export const privacyPolicy = {
  title: "Politique de Confidentialité",
  introduction: "Cette politique de confidentialité s'applique à l'application Brindalo (ci-après dénommée \"Application\") pour appareils mobiles, créée par Aronin LLC (ci-après dénommé \"Fournisseur de Services\") en tant que service commercial. Ce service est destiné à être utilisé \"TEL QUEL\".",
  informationCollection: {
    title: "Collecte et Utilisation des Informations",
    content: "L'Application collecte des informations lorsque vous la téléchargez et l'utilisez. Ces informations peuvent inclure :",
    list: [
      "L'adresse de protocole Internet de votre appareil (par exemple, l'adresse IP)",
      "Les pages de l'Application que vous visitez, la date et l'heure de votre visite, le temps passé sur ces pages",
      "Le temps passé sur l'Application",
      "Le système d'exploitation que vous utilisez sur votre appareil mobile"
    ],
    additionalInfo: "L'Application ne recueille pas d'informations précises sur l'emplacement de votre appareil mobile. Le Fournisseur de Services peut utiliser les informations que vous avez fournies pour vous contacter de temps en temps afin de vous fournir des informations importantes, des avis requis et des promotions marketing. Pour une meilleure expérience, lors de l'utilisation de l'Application, le Fournisseur de Services peut vous demander de fournir certaines informations personnellement identifiables. Les informations demandées par le Fournisseur de Services seront conservées et utilisées comme décrit dans cette politique de confidentialité."
  },
  thirdPartyAccess: {
    title: "Accès par des Tiers",
    content: "Seules des données agrégées et anonymisées sont périodiquement transmises à des services externes pour aider le Fournisseur de Services à améliorer l'Application et leur service. Le Fournisseur de Services peut partager vos informations avec des tiers de la manière décrite dans cette déclaration de confidentialité. Veuillez noter que l'Application utilise des services tiers qui ont leur propre Politique de Confidentialité concernant le traitement des données. Voici les liens vers la Politique de Confidentialité des fournisseurs de services tiers utilisés par l'Application :",
    services: [
      "Services Google Play",
      "Google Analytics pour Firebase",
      "Firebase Crashlytics",
      "RevenueCat"
    ],
    disclosure: "Le Fournisseur de Services peut divulguer les Informations Fournies par l'Utilisateur et les Informations Collectées Automatiquement : si la loi l'exige, par exemple pour se conformer à une assignation à comparaître ou à une procédure légale similaire ; lorsqu'ils croient de bonne foi que la divulgation est nécessaire pour protéger leurs droits, protéger votre sécurité ou la sécurité d'autrui, enquêter sur une fraude ou répondre à une demande gouvernementale ; avec leurs fournisseurs de services de confiance qui travaillent en leur nom, n'ont pas d'utilisation indépendante des informations que nous leur divulguons et ont accepté d'adhérer aux règles énoncées dans cette déclaration de confidentialité."
  },
  optOutRights: {
    title: "Droits de Retrait",
    content: "Vous pouvez facilement arrêter toute collecte d'informations par l'Application en la désinstallant. Vous pouvez utiliser les processus de désinstallation standard qui peuvent être disponibles dans le cadre de votre appareil mobile ou via le marché ou le réseau d'applications mobiles."
  },
  dataRetention: {
    title: "Politique de Conservation des Données",
    content: "Le Fournisseur de Services conservera les données fournies par l'utilisateur aussi longtemps que vous utiliserez l'Application et pendant une durée raisonnable par la suite. Si vous souhaitez qu'ils suppriment les Données Fournies par l'Utilisateur que vous avez fournies via l'Application, veuillez les contacter à brindalo.pro@gmail.com et ils répondront dans un délai raisonnable."
  },
  children: {
    title: "Enfants",
    content: "Le Fournisseur de Services n'utilise pas l'Application pour solliciter sciemment des données auprès d'enfants de moins de 13 ans ou leur faire du marketing. L'Application ne s'adresse à personne de moins de 13 ans. Le Fournisseur de Services ne collecte pas sciemment d'informations personnellement identifiables d'enfants de moins de 13 ans. Dans le cas où le Fournisseur de Services découvre qu'un enfant de moins de 13 ans a fourni des informations personnelles, le Fournisseur de Services les supprimera immédiatement de ses serveurs. Si vous êtes un parent ou un tuteur et que vous savez que votre enfant nous a fourni des informations personnelles, veuillez contacter le Fournisseur de Services (brindalo.pro@gmail.com) afin qu'ils puissent prendre les mesures nécessaires."
  },
  security: {
    title: "Sécurité",
    content: "Le Fournisseur de Services se soucie de la protection de la confidentialité de vos informations. Le Fournisseur de Services fournit des garanties physiques, électroniques et procédurales pour protéger les informations qu'il traite et maintient."
  },
  changes: {
    title: "Modifications",
    content: "Cette Politique de Confidentialité peut être mise à jour de temps à autre pour quelque raison que ce soit. Le Fournisseur de Services vous informera de toute modification de la Politique de Confidentialité en mettant à jour cette page avec la nouvelle Politique de Confidentialité. Il vous est conseillé de consulter régulièrement cette Politique de Confidentialité pour tout changement, car l'utilisation continue est considérée comme une approbation de tous les changements.",
    effectiveDate: "Cette politique de confidentialité est en vigueur à partir du 2024-10-20"
  },
  consent: {
    title: "Votre Consentement",
    content: "En utilisant l'Application, vous consentez au traitement de vos informations comme énoncé dans cette Politique de Confidentialité maintenant et telle que modifiée par nous."
  },
  contact: {
    title: "Nous Contacter",
    content: "Si vous avez des questions concernant la confidentialité lors de l'utilisation de l'Application, ou si vous avez des questions sur les pratiques, veuillez contacter le Fournisseur de Services par e-mail à brindalo.pro@gmail.com."
  }
};
