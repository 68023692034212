import React from "react";
import styled from "styled-components";
import { createGlobalStyle } from "styled-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600&display=swap');

  * {
    box-sizing: border-box;
  }

  body {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    min-height: 100vh;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
`;

const ContentWrapper = styled.div`
  flex: 1;
  padding: 80px 5% 0; // Added top padding to account for fixed navbar
`;

const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 5%;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const Logo = styled(Link)`
  font-size: 24px;
  font-weight: 600;
  text-decoration: none;
  color: #000;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: #000;
`;

const LanguageToggle = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #000;
`;

const Footer = styled.footer`
  background-color: #f8f8f8;
  padding: 20px 0;
  border-top: 1px solid #e7e7e7;
  width: 100%;
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 5%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const FooterLinks = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 10px;
  }
`;

const FooterLink = styled(Link)`
  color: #333;
  text-decoration: none;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
`;

const FooterContact = styled.p`
  margin: 0;
  font-size: 14px;
  color: #666;
`;

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { t, i18n } = useTranslation();

  const toggleLanguage = () => {
    const nextLanguage = i18n.language === 'en' ? 'fr' : i18n.language === 'fr' ? 'es' : 'en';
    i18n.changeLanguage(nextLanguage);
  };

  return (
    <>
      <GlobalStyle />
      <Container>
        <Navbar>
          <Logo to="/">TUYOPASS</Logo>
          <NavLinks>
            <NavLink to="/#about">{t("landingPage.nav.about")}</NavLink>
            <NavLink to="/#contact">{t("landingPage.nav.contact")}</NavLink>
            <LanguageToggle onClick={toggleLanguage}>
              {i18n.language.toUpperCase()}
            </LanguageToggle>
          </NavLinks>
        </Navbar>
        <ContentWrapper>
          {children}
        </ContentWrapper>
        <Footer>
          <FooterContent>
            <FooterLinks>
              <FooterLink to="/privacy-policy">{t("landingPage.footer.privacyPolicy")}</FooterLink>
              {/* <FooterLink to="/terms-conditions">{t("landingPage.footer.termsConditions")}</FooterLink> */}
            </FooterLinks>
            <FooterContact>hi@tuyopass.com</FooterContact>
          </FooterContent>
        </Footer>
      </Container>
    </>
  );
};

export default Layout;
