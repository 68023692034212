export const privacyPolicy = {
  title: "Política de Privacidad",
  introduction: "Esta política de privacidad se aplica a la aplicación Brindalo (en adelante, \"Aplicación\") para dispositivos móviles creada por Aronin LLC (en adelante, \"Proveedor de Servicios\") como un servicio comercial. Este servicio está destinado a ser utilizado \"TAL CUAL\".",
  informationCollection: {
    title: "Recopilación y Uso de Información",
    content: "La Aplicación recopila información cuando la descarga y la utiliza. Esta información puede incluir:",
    list: [
      "La dirección de Protocolo de Internet de su dispositivo (por ejemplo, dirección IP)",
      "Las páginas de la Aplicación que visita, la fecha y hora de su visita, el tiempo que pasa en esas páginas",
      "El tiempo que pasa en la Aplicación",
      "El sistema operativo que utiliza en su dispositivo móvil"
    ],
    additionalInfo: "La Aplicación no recopila información precisa sobre la ubicación de su dispositivo móvil. El Proveedor de Servicios puede utilizar la información que proporcionó para contactarlo de vez en cuando para proporcionarle información importante, avisos requeridos y promociones de marketing. Para una mejor experiencia, mientras usa la Aplicación, el Proveedor de Servicios puede requerir que proporcione cierta información de identificación personal. La información que el Proveedor de Servicios solicita será retenida y utilizada como se describe en esta política de privacidad."
  },
  thirdPartyAccess: {
    title: "Acceso de Terceros",
    content: "Solo se transmiten periódicamente datos agregados y anonimizados a servicios externos para ayudar al Proveedor de Servicios a mejorar la Aplicación y su servicio. El Proveedor de Servicios puede compartir su información con terceros de las formas descritas en esta declaración de privacidad. Tenga en cuenta que la Aplicación utiliza servicios de terceros que tienen su propia Política de Privacidad sobre el manejo de datos. A continuación se encuentran los enlaces a la Política de Privacidad de los proveedores de servicios de terceros utilizados por la Aplicación:",
    services: [
      "Servicios de Google Play",
      "Google Analytics para Firebase",
      "Firebase Crashlytics",
      "RevenueCat"
    ],
    disclosure: "El Proveedor de Servicios puede divulgar Información Proporcionada por el Usuario e Información Recopilada Automáticamente: según lo requiera la ley, como para cumplir con una citación o proceso legal similar; cuando creen de buena fe que la divulgación es necesaria para proteger sus derechos, proteger su seguridad o la seguridad de otros, investigar fraudes o responder a una solicitud gubernamental; con sus proveedores de servicios de confianza que trabajan en su nombre, no tienen un uso independiente de la información que les revelamos y han acordado adherirse a las reglas establecidas en esta declaración de privacidad."
  },
  optOutRights: {
    title: "Derechos de Exclusión",
    content: "Puede detener toda recopilación de información por parte de la Aplicación fácilmente desinstalándola. Puede utilizar los procesos de desinstalación estándar que pueden estar disponibles como parte de su dispositivo móvil o a través del mercado o red de aplicaciones móviles."
  },
  dataRetention: {
    title: "Política de Retención de Datos",
    content: "El Proveedor de Servicios retendrá los datos proporcionados por el usuario mientras use la Aplicación y durante un tiempo razonable después. Si desea que eliminen los Datos Proporcionados por el Usuario que ha proporcionado a través de la Aplicación, comuníquese con ellos a brindalo.pro@gmail.com y responderán en un tiempo razonable."
  },
  children: {
    title: "Niños",
    content: "El Proveedor de Servicios no utiliza la Aplicación para solicitar conscientemente datos o comercializar a niños menores de 13 años. La Aplicación no se dirige a nadie menor de 13 años. El Proveedor de Servicios no recopila conscientemente información de identificación personal de niños menores de 13 años. En caso de que el Proveedor de Servicios descubra que un niño menor de 13 años ha proporcionado información personal, el Proveedor de Servicios la eliminará inmediatamente de sus servidores. Si usted es padre o tutor y sabe que su hijo nos ha proporcionado información personal, comuníquese con el Proveedor de Servicios (brindalo.pro@gmail.com) para que puedan tomar las acciones necesarias."
  },
  security: {
    title: "Seguridad",
    content: "El Proveedor de Servicios se preocupa por salvaguardar la confidencialidad de su información. El Proveedor de Servicios proporciona salvaguardas físicas, electrónicas y de procedimiento para proteger la información que el Proveedor de Servicios procesa y mantiene."
  },
  changes: {
    title: "Cambios",
    content: "Esta Política de Privacidad puede actualizarse de vez en cuando por cualquier motivo. El Proveedor de Servicios le notificará de cualquier cambio en la Política de Privacidad actualizando esta página con la nueva Política de Privacidad. Se le aconseja consultar esta Política de Privacidad regularmente para cualquier cambio, ya que el uso continuado se considera aprobación de todos los cambios.",
    effectiveDate: "Esta política de privacidad está vigente a partir del 2024-10-20"
  },
  consent: {
    title: "Su Consentimiento",
    content: "Al utilizar la Aplicación, usted está consintiendo el procesamiento de su información como se establece en esta Política de Privacidad ahora y según sea enmendada por nosotros."
  },
  contact: {
    title: "Contáctenos",
    content: "Si tiene alguna pregunta sobre la privacidad mientras usa la Aplicación, o tiene preguntas sobre las prácticas, comuníquese con el Proveedor de Servicios por correo electrónico a brindalo.pro@gmail.com."
  }
};
