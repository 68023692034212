export const landingPage = {
  nav: {
    about: "About",
    contact: "Contact"
  },
  comingSoon: "Coming Soon",
  hero: {
    title: "Do more, spend less",
    subtitle: "Unlock a world of savings and experiences with TuyoPass. Your passport to adventure, indulgence, and smart living.",
    cta: "Join the Waitlist"
  },
  about: {
    title: "About TuyoPass",
    text: "TuyoPass is your key to unlocking exclusive discounts across a spectrum of experiences. From cozy cafés to thrilling adventures, we're here to fuel your passions without breaking the bank. With TuyoPass, every day becomes an opportunity to explore, indulge, and live life to the fullest – all while keeping your wallet happy."
  },
  mission: {
    title: "Our Mission",
    text: "We're on a mission to democratize experiences. By connecting savvy subscribers with exclusive discounts, we're not just saving you money – we're opening doors to new adventures, flavors, and memories. Our goal? To empower you to do more of what you love, try new things, and live life without limits."
  },
  vision: {
    title: "Our Vision",
    text: "Imagine a world where budget constraints don't hold you back from experiencing life's joys. That's the world we're creating with TuyoPass. We envision a community of adventurers, foodies, fitness enthusiasts, and culture lovers, all empowered to pursue their passions more frequently and more affordably."
  },
  values: {
    title: "Our Values",
    text: "Adventure: We believe in saying 'yes' to new experiences.\nAccessibility: Great experiences should be within everyone's reach.\nCommunity: We're building a network of like-minded experience seekers.\nValue: We're committed to bringing you unbeatable deals and discounts."
  },
  footer: {
    privacyPolicy: "Privacy Policy",
    termsConditions: "Terms & Conditions",
    contact: "Contact"
  }
};
