import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Layout from "./Layout";


const Hero = styled.div`
  margin-top: 30px;
`;

const HeroTitle = styled.h1`
  font-size: 72px;
  font-weight: 300;
  margin-top: 30px;
  margin-bottom: 30px;
  word-wrap: break-word;
  max-width: 100%;
`;

const HeroSubtitle = styled.p`
  font-size: 24px;
  font-weight: 400;
  max-width: 100%;
  margin-bottom: 30px;
`;

const Section = styled.section`
  margin-bottom: 40px; /* Space between sections */

  h2 {
    margin-bottom: 10px; /* Space between header and paragraph */
  }
`;

const ComingSoon = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  color: #333;
  position: relative;
  overflow: hidden;
  border: 3px solid transparent;
  animation: borderGlow 5s infinite alternate,
    boxShadowGlow 5s infinite alternate;

  @keyframes borderGlow {
    0% {
      border-color: rgba(173, 216, 230, 0.5); /* Light Blue */
    }
    50% {
      border-color: rgba(224, 255, 255, 0.5); /* Light Cyan */
    }
    100% {
      border-color: rgba(173, 216, 230, 0.5); /* Light Blue */
    }
  }

  @keyframes boxShadowGlow {
    0% {
      box-shadow: 0 0 10px rgba(173, 216, 230, 0.2); /* Light Blue */
    }
    50% {
      box-shadow: 0 0 10px rgba(224, 255, 255, 0.2); /* Light Cyan */
    }
    100% {
      box-shadow: 0 0 10px rgba(173, 216, 230, 0.2); /* Light Blue */
    }
  }
`;

const LandingPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <ComingSoon>Coming Soon</ComingSoon>
      <Hero>
        <HeroTitle>{t("landingPage.hero.title")}</HeroTitle>
        <HeroSubtitle>{t("landingPage.hero.subtitle")}</HeroSubtitle>
      </Hero>
      <Section>
        <h2>{t("landingPage.about.title")}</h2>
        <p>{t("landingPage.about.text")}</p>
      </Section>
      <Section>
        <h2>{t("landingPage.mission.title")}</h2>
        <p>{t("landingPage.mission.text")}</p>
      </Section>
      <Section>
        <h2>{t("landingPage.vision.title")}</h2>
        <p>{t("landingPage.vision.text")}</p>
      </Section>
      <Section>
        <h2>{t("landingPage.values.title")}</h2>
        <p>{t("landingPage.values.text")}</p>
      </Section>
    </Layout>
  );
};

export default LandingPage;
