export const landingPage = {
  nav: {
    about: "Acerca de",
    contact: "Contacto"
  },
  comingSoon: "Próximamente",
  hero: {
    title: "Haz más, gasta menos",
    subtitle: "Desbloquea un mundo de ahorros y experiencias con TuyoPass. Tu pasaporte para la aventura, la indulgencia y una vida inteligente.",
    cta: "Únete a la lista de espera"
  },
  about: {
    title: "Acerca de TuyoPass",
    text: "TuyoPass es tu llave para desbloquear descuentos exclusivos en un amplio espectro de experiencias. Desde acogedores cafés hasta emocionantes aventuras, estamos aquí para alimentar tus pasiones sin romper tu bolsillo. Con TuyoPass, cada día se convierte en una oportunidad para explorar, disfrutar y vivir la vida al máximo, todo mientras mantienes tu billetera feliz."
  },
  mission: {
    title: "Nuestra Misión",
    text: "Estamos en una misión para democratizar las experiencias. Al conectar a suscriptores inteligentes con descuentos exclusivos, no solo te estamos ahorrando dinero, sino que estamos abriendo puertas a nuevas aventuras, sabores y recuerdos. ¿Nuestro objetivo? Empoderarte para que hagas más de lo que amas, pruebes cosas nuevas y vivas la vida sin límites."
  },
  vision: {
    title: "Nuestra Visión",
    text: "Imagina un mundo donde las limitaciones presupuestarias no te impidan experimentar las alegrías de la vida. Ese es el mundo que estamos creando con TuyoPass. Visualizamos una comunidad de aventureros, amantes de la comida, entusiastas del fitness y amantes de la cultura, todos empoderados para perseguir sus pasiones con más frecuencia y de manera más asequible."
  },
  values: {
    title: "Nuestros Valores",
    text: "Aventura: Creemos en decir 'sí' a nuevas experiencias.\nAccesibilidad: Las grandes experiencias deberían estar al alcance de todos.\nComunidad: Estamos construyendo una red de buscadores de experiencias afines.\nValor: Estamos comprometidos a brindarte ofertas y descuentos imbatibles."
  },
  footer: {
    privacyPolicy: "Política de privacidad",
    termsConditions: "Términos y condiciones",
    contact: "Contacto"
  }
};
