export const landingPage = {
  nav: {
    about: "À propos",
    contact: "Contact"
  },
  comingSoon: "Bientôt disponible",
  hero: {
    title: "Faites plus, dépensez moins",
    subtitle: "Débloquez un monde d'économies et d'expériences avec TuyoPass. Votre passeport pour l'aventure, l'indulgence et une vie intelligente.",
    cta: "Rejoignez la liste d'attente"
  },
  about: {
    title: "À propos de TuyoPass",
    text: "TuyoPass est votre clé pour débloquer des réductions exclusives sur un large éventail d'expériences. Des cafés confortables aux aventures palpitantes, nous sommes là pour alimenter vos passions sans vider votre portefeuille. Avec TuyoPass, chaque jour devient une opportunité d'explorer, de se faire plaisir et de vivre pleinement - tout en gardant votre portefeuille heureux."
  },
  mission: {
    title: "Notre Mission",
    text: "Notre mission est de démocratiser les expériences. En connectant des abonnés avisés avec des réductions exclusives, nous ne vous faisons pas seulement économiser de l'argent - nous ouvrons des portes vers de nouvelles aventures, saveurs et souvenirs. Notre objectif ? Vous permettre de faire plus de ce que vous aimez, d'essayer de nouvelles choses et de vivre sans limites."
  },
  vision: {
    title: "Notre Vision",
    text: "Imaginez un monde où les contraintes budgétaires ne vous empêchent pas de profiter des joies de la vie. C'est le monde que nous créons avec TuyoPass. Nous envisageons une communauté d'aventuriers, de gourmets, de passionnés de fitness et d'amateurs de culture, tous habilités à poursuivre leurs passions plus fréquemment et de manière plus abordable."
  },
  values: {
    title: "Nos Valeurs",
    text: "Aventure : Nous croyons qu'il faut dire 'oui' aux nouvelles expériences.\nAccessibilité : Les grandes expériences devraient être à la portée de tous.\nCommunauté : Nous construisons un réseau de chercheurs d'expériences partageant les mêmes idées.\nValeur : Nous nous engageons à vous apporter des offres et des réductions imbattables."
  },
  footer: {
    privacyPolicy: "Politique de confidentialité",
    termsConditions: "Conditions générales",
    contact: "Contact"
  }
};
